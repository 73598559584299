import axios from "@/utils/axios";
import store from "../store";
const getEmpresasGrupo = async (id = null, showInativas = true) => {
  try {
    return await store.dispatch("DBEmpresas/getEmpresa", { id, showInativas });
  } catch (error) {
    console.log("getEmpresasGrupo error", error);
  }
};

const getEmpresasGrupoAPI = async ( showInativas = true ) => {
  try {
    return (
      await axios.get("/empresas/empresasGrupo", { params: { showInativas } })
    ).data;
  } catch (error) {
    console.log("getEmpresasGrupoApi ERROR", error);
  }
};

const getCertificadosNFE = async () => {
  let result;
  await axios
    .get(`/empresas/configuracoes/certificados`)
    .then((res) => {
      result = res.data.data;
    })
    .catch((err) => {
      console.log("erro ao buscar certificados", err);
    });
  return result;
};

const getEmpresaConfigNfe = async (cnpj) => {
  return (await axios.get(`/empresas/configuracoes/notafiscal/${cnpj}`)).data;
};

const storeEmpresaConfigNfe = async (empresa) => {
  let result;

  await axios
    .post(`/empresas/configuracoes/notafiscal`, empresa)
    .then((res) => {
      result = res;
    })
    .catch((err) => {
      throw err;
    });

  return result;
};

const udpateEmpresaConfigNfe = async (cnpj, empresa) => {
  let result;

  await axios
    .patch(`/empresas/configuracoes/notafiscal/${cnpj}`, empresa)
    .then((res) => {
      result = res;
    })
    .catch((err) => {
      throw err;
    });

  return result;
};

// const storeEmpresaConfigNfe = async () =>{
//   return await
// }

const storeEmpresa = async (data) => {
  let result = (await axios.post("/empresas/store", data)).data;
  await store.dispatch("DBEmpresas/refreshEmpresas");
  return result;
};

const del = async (data) => {
  return (await axios.post("/empresas/delete", data)).data;
};

export default {
  getEmpresasGrupo,
  storeEmpresa,
  del,
  getEmpresasGrupoAPI,
  getCertificadosNFE,
  getEmpresaConfigNfe,
  storeEmpresaConfigNfe,
  udpateEmpresaConfigNfe,
};
